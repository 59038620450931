import React, { useState } from 'react';
import { Input } from 'antd';

const InputW = ({ defaultValue = '', widthEdit = 0 as any, onPressEnter = null as any, onInput = null as any, onBlur = null as any, ...rest }) => {
  const [value, setValue] = useState('');
  const [inputWidth, setInputWidth] = useState(1);

  const handleInputChange = (e: any) => {
    if (onInput) {
      onInput(e)
    }
    const inputValue = e.target.value;
    setValue(inputValue);

    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.whiteSpace = 'pre';
    span.style.position = 'absolute';
    span.textContent = inputValue || ' ';
    document.body.appendChild(span);

    setInputWidth(span.offsetWidth + 0);
    document.body.removeChild(span);
  };

  return (
    <Input
      className='InputW'
      defaultValue={defaultValue}
      onChange={handleInputChange}
      onKeyUp={(e: any)=>{
        if (onPressEnter && e.keyCode === 13) {
          onPressEnter(e)
        }
      }}
      onPressEnter={(e) => {
        if (onPressEnter) {
          onPressEnter(e)
        }
      }}
      onBlur={onBlur}
      style={{
        width: `${inputWidth}px`,
        minWidth: `${widthEdit < 20 ? 20 : widthEdit}px`,
      }}
      placeholder="Nhập nội dung..."
      {...rest}
    />
  );
};

export default InputW;
