import { useEffect, useRef } from 'react';

const useActivityTimeout = (timeout: number, onTimeout: () => void) => {
  const timer = useRef<NodeJS.Timeout | null>(null);

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      onTimeout();
    }, timeout);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'click'];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    resetTimer(); // Đặt lại timer khi hook được sử dụng

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [timeout, onTimeout]);

  return null;
};

export default useActivityTimeout;
