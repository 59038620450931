import React from "react";
interface Props {
  hourinshift?: any
  changeHourinshift?: any
  disabled?: boolean
}
const Shift: React.FC<Props> = (props) => {
  const { hourinshift, changeHourinshift, disabled = false } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Chuyển đổi giá trị nhập vào thành số
    const value = parseInt(event.target.value)

    if (isNaN(value)) {
      return
    }
    // Kiểm tra nếu giá trị nhập vào nằm trong giới hạn 1 - 24
    if (value < 0) {
      changeHourinshift(0)
    } else if (value > 8) {
      changeHourinshift(8)
    } else {
      changeHourinshift(value)
    }
  }

  return (
    <div className="content collapse-shift">
      <span>
        Once player matched for a first shift, the next shift starts getting matched
      </span>
      <span className="ctrl-input-number">
        <input
          type="number"
          value={hourinshift}
          disabled={disabled}
          onChange={handleChange}
          className="rs-input"
        />
      </span>
      <div>
        hour after it begins.
      </div>
    </div>
  )
}

export default Shift
